import React, { useRef, useLayoutEffect } from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text } from '@/components/Text'
import { Wrapper, StyledDivider } from './styled'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import gsap from 'gsap'

const PlanetPage: React.FC = () => {
  const { t } = useTranslation()
  const dividerRef = useRef(null)

  useLayoutEffect(() => {
    const tl = gsap.timeline({})
    tl.fromTo(
      dividerRef.current,
      1.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  })
  return (
    <>
      <Page
        content={
          <PageWrapper>
            <Wrapper>
              <Text
                title={t('dna.join_toggle4_title', {
                  defaultValue: 'WE PERSEVERE',
                })}
                text={t('dna.join_toggle4_body', {
                  defaultValue:
                    'We do things that have not been done before, we find solutions for complex situations, we do things that are difficult to do. Sometimes people even think we do things that are impossible. But we do them. We think hard, we push the boundaries and we deliver.',
                })}
              />
              <StyledDivider ref={dividerRef} />
            </Wrapper>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/values"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default PlanetPage
